<template>
  <div class="error-wrapper">
    <div class="container">
      <div class="svg-wrraper">
        <errorPage5 />
      </div>
      <div class="col-md-8 offset-md-2">
        <h3>Página No Encontrada</h3>
        <p class="sub-content">
          La página a la que estás intentando acceder no está disponible en este
          momento. Esto puede deberse a que la página no existe o ha sido
          trasladada.
        </p>
        <router-link class="btn btn-primary" to="/buscar">
          Ir a la página principal
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import errorPage5 from "@/svg/errorPage5.vue";
export default {
  components: {
    errorPage5,
  },
};
</script>
