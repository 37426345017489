<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import TablaAccionesMultiples from "@/components/tablaAccionesMultiples.vue";
import * as XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import { apiValidacion } from "../../../boot/axios";
export default {
  data() {
    return {
      tamañosFormatosQr: [
        { tamaño: "80x80", escala: 1.85 },
        { tamaño: "128x128", escala: 1.15 },
        { tamaño: "256x256", escala: 0.575 },
        { tamaño: "512x512", escala: 0.2875 },
      ],
      rangoFechas: [],
      campañaSeleccionada: "",
      estatusActivacion: "",
      foliosQr: [],
      foliosFiltrados: [],
      urlsImagenesQrs: [],
      headersTabla: [
        "Folio",
        "Fecha Activación",
        "Vigencia inicio",
        "Vigencia Fin",
        "Activada",
        "Monto activación",
        "Campaña",
        "Acciones",
      ],
      campos: [
        { tipo: "texto", clave: "tarjeta" },
        { tipo: "texto", clave: "fecha_activacion" },
        { tipo: "texto", clave: "vig_i_act" },
        { tipo: "texto", clave: "vig_f_act" },
        { tipo: "texto", clave: "asignada" },
        { tipo: "texto", clave: "mon_act" },
        { tipo: "texto", clave: "desc_act" },
        { tipo: "botones" },
      ],
      data: [
        {
          tarjeta: "7100000123501",
          fecha_activacion: "2023-12-25T09:05:02",
          vig_i_act: "2023-12-20",
          vig_f_act: "2024-01-31",
          asignada: "true",
          mon_act: "50",
          desc_act: "DIC2023_50_SK",
        },
      ],
      accionesBotones: [
        {
          titulo: "Descargar QR",
          claseBoton: "btn btn-primary d-block",
          tipoFuncion: "1",
        },
        // {
        //   titulo: "Regenerar QR",
        //   claseBoton: "btn btn-secondary d-block",
        //   tipoFuncion: "2",
        // },
        {
          titulo: "Imprimir formato",
          claseBoton: "btn btn-success d-block",
          tipoFuncion: "3",
        },
      ],
      campañasActuales: [],
      zip: new JSZip(),
    };
  },
  components: {
    TablaAccionesMultiples,
  },
  created() {
    this.mostrarAlertaNoDisponible();
    this.obtenerCampañasQrs();
    const inicioMesActual = moment()
      .startOf("month")
      .format("YYYY-MM-DD hh:mm");
    const finMesActual = moment().endOf("month").format("YYYY-MM-DD hh:mm");
    this.rangoFechas = [inicioMesActual, finMesActual];
    // this.obtenerTarjetasPreregistro();
    // this.descargarFormatoImpresionIndividual({ tarjeta: "7100000124871" });
  },
  computed: {
    ...mapState("validacion", ["campañas"]),
  },
  methods: {
    ...mapActions("validacion", [
      "obtenerCampañasQrs",
      "consultarTarjetasPreregistro",
      "obtenerUrlImagenQr",
    ]),
    async obtenerTarjetasPreregistro() {
      try {
        const res = await this.consultarTarjetasPreregistro({
          fechaInicio: this.rangoFechas[0],
          fechaFin: this.rangoFechas[1],
        });
        this.foliosQr = res;
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.filtrarFoliosQr();
      }
    },
    filtrarFoliosQr() {
      console.log("filtrando");
      console.log(this.foliosQr);
      this.foliosFiltrados = this.foliosQr;
      console.log(this.foliosFiltrados);
      if (this.campañaSeleccionada != "") {
        this.foliosFiltrados = this.foliosFiltrados.filter((folio) => {
          return folio.desc_act == this.campañaSeleccionada;
        });
      }
      if (this.estatusActivacion != "") {
        this.foliosFiltrados = this.foliosFiltrados.filter((folio) => {
          return folio.asignada.toString() == this.estatusActivacion;
        });
      }
    },
    exportarExcel() {
      try {
        const dataFoliosQr = [];
        this.foliosFiltrados.map((folio) => {
          dataFoliosQr.push([
            folio["tarjeta"],
            folio["fecha_activacion"],
            folio["vig_i_act"],
            folio["vig_f_act"],
            folio["asignada"],
            folio["mon_act"],
            folio["desc_act"],
          ]);
        });

        console.log(dataFoliosQr);
        const ws = XLSX.utils.aoa_to_sheet([
          [
            "Folio",
            "Fecha Activación",
            "Vigencia inicio",
            "Vigencia Fin",
            "Activada",
            "Monto activación",
            "Campaña",
          ],
          ...dataFoliosQr,
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DATOS MAESTROS");
        const fileName = `Reporte_Folios_Qrs_${moment(
          this.rangoFechas[0]
        ).format("DD-MM-YYYY")}_${moment(this.rangoFechas[1]).format(
          "DD-MM-YYYY"
        )}.xlsx`;

        XLSX.writeFile(wb, fileName);
      } catch (error) {
        console.error("Error al exportar a Excel:", error);
      }
    },
    async consultarUrlsQrs() {
      try {
        await Promise.all(
          this.foliosFiltrados.map(async (folio) => {
            const url = await this.obtenerUrlImagenQr(folio.tarjeta);
            if (url != "") {
              this.urlsImagenesQrs.push({ folio: folio.tarjeta, url: url });
            }
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    async descargarImagenes() {
      try {
        await this.consultarUrlsQrs();
        await Promise.all(
          this.urlsImagenesQrs.map(async (objetoUrlImagen) => {
            const resp = await axios.get(objetoUrlImagen.url, {
              responseType: "blob",
            });
            console.log(resp);
            this.zip.file(`${objetoUrlImagen.folio}.png`, resp.data);
          })
        );
        const blob = await this.zip.generateAsync({ type: "blob" });

        const foliosOrdenados = this.urlsImagenesQrs.sort((a, b) => {
          a.folio - b.folio
        });

        console.log(foliosOrdenados);
        
        saveAs(
          blob,
          `Folios_Qrs_${foliosOrdenados[0].folio}_${foliosOrdenados[foliosOrdenados.length-1].folio}.zip`
        );
      } catch (error) {
        console.log(error);
      }
    },
    async descargarImagenIndividualQr(dataFolio) {
      console.log(dataFolio);
      const url = await this.obtenerUrlImagenQr(dataFolio.tarjeta);
      const imagen = await axios.get(url, {
        responseType: "blob",
      });
      saveAs(imagen.data, `${dataFolio.tarjeta}.png`);
    },
    async descargarFormatoImpresionIndividual(dataFolio) {
      const urlImagen = await this.obtenerUrlImagenQr(dataFolio.tarjeta);
      const imagen = await axios.get(urlImagen, {
        responseType: "blob",
      });

      // const url = "http://localhost:5000/api/v1/validacion/";

      const existingPdfBytes = await apiValidacion({
        method: "GET",
        url: "/formato",
        responseType: "arraybuffer",
      });

      const pdfDoc = await PDFDocument.load(existingPdfBytes.data);

      const tamañoEncontrado = this.tamañosFormatosQr.find((tamañoFormato) =>
        urlImagen.includes(tamañoFormato.tamaño)
      );

      console.log(tamañoEncontrado.escala);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      const imageBlob = imagen.data;
      const imageArrayBuffer = await imageBlob.arrayBuffer();

      const image = await pdfDoc.embedPng(imageArrayBuffer);
      const { width, height } = image.scale(
        tamañoEncontrado.escala ? tamañoEncontrado.escala : 1.15
      );
      firstPage.drawImage(image, {
        x: 222,
        y: 564,
        width: width,
        height: height,
      });

      const pdfBytes = await pdfDoc.save();

      download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
      console.log("Guardado");
    },
    async descargarFormatosImpresion() {
      console.log("click");
      try {
        const existingPdfBytes = await apiValidacion({
          method: "GET",
          url: "/formato",
          responseType: "arraybuffer",
        });

        const pdfDoc = await PDFDocument.load(existingPdfBytes.data);

        const pages = pdfDoc.getPages();

        const firstPage = pages[0];

        await this.consultarUrlsQrs();
        // console.log("descargando");
        await Promise.all(
          this.urlsImagenesQrs.map(async (objetoUrlImagen) => {
            console.log(objetoUrlImagen);
            const imagen = await axios.get(objetoUrlImagen.url, {
              responseType: "blob",
            });
            const tamañoEncontrado = this.tamañosFormatosQr.find(
              (tamañoFormato) =>
                objetoUrlImagen.url.includes(tamañoFormato.tamaño)
            );

            const imageBlob = imagen.data;
            const imageArrayBuffer = await imageBlob.arrayBuffer();

            const image = await pdfDoc.embedPng(imageArrayBuffer);
            const { width, height } = image.scale(
              tamañoEncontrado.escala ? tamañoEncontrado.escala : 1.15
            );
            console.log(tamañoEncontrado.escala);

            firstPage.drawImage(image, {
              x: 222,
              y: 564,
              width: width,
              height: height,
            });

            const pdfBytes = await pdfDoc.save();
            this.zip.file(`${objetoUrlImagen.folio}.pdf`, pdfBytes);
            console.log(objetoUrlImagen.folio);
          })
        );
        console.log("Guardando Zip");
        const blob = await this.zip.generateAsync({ type: "blob" });

        const foliosOrdenados = this.urlsImagenesQrs.sort((a, b) => {
          a.folio - b.folio
        });

        console.log(foliosOrdenados);

        
        saveAs(
          blob,
          `Formatos_Impresión_Qrs_${foliosOrdenados[0].folio}_${foliosOrdenados[foliosOrdenados.length-1].folio}.zip`
        );
        // console.log("termino");
      } catch (error) {
        console.log(error);
      }
    },
    mostrarAlertaNoDisponible() {
      this.$swal({
        icon: "warning",
        type: "warning",
        title: "Esta sección no se encuentra disponible actualmente",
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if(result.isConfirmed){
          this.$router.push("/");
        } 
      });
    },
  },
  watch: {
    campañas(nuevasCampañas) {
      for (const [key, campaña] of Object.entries(nuevasCampañas)) {
        this.campañasActuales.push(campaña);
      }
    },
    rangoFechas(nuevasFechas) {
      this.obtenerTarjetasPreregistro();
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Consultar folios QR</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card w-100 px-1">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-lg-4 col-12">
            <label class="col-form-label pt-0" for="campaña">Campaña</label>
            <select
              class="form-select digits"
              id="campañas"
              v-model="campañaSeleccionada"
              @change="filtrarFoliosQr()"
            >
              <option value="">Todas las campañas</option>
              <option v-for="campaña in campañasActuales">
                {{ campaña.descripcionActivacion }}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-12">
            <label class="col-form-label pt-0" for="campaña">Fechas establecidas de Vigencia</label>
            <datepicker
              class="datepicker-here digits"
              range
              id="fechaPedidos"
              :enable-time-picker="false"
              type="date"
              locale="es"
              format="dd/MM/yyyy"
              select-text="Confirmar"
              cancel-text="Cancelar"
              v-model="rangoFechas"
            />
          </div>
          <div class="col-lg-4 col-12">
            <label class="col-form-label pt-0" for="campaña"
              >Estatus de activación</label
            >
            <select
              class="form-select digits"
              id="exampleFormControlSelect30"
              v-model="estatusActivacion"
              @change="filtrarFoliosQr()"
            >
              <option value="true">Activadas</option>
              <option value="false">No activadas</option>
              <option value="">Ambas</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <TablaAccionesMultiples
            :data="foliosFiltrados"
            :headers="headersTabla"
            :campos="campos"
            :lista-botones="accionesBotones"
            @descargarQrFolio="descargarImagenIndividualQr"
            @imprimirFormato="descargarFormatoImpresionIndividual"
          />
        </div>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3 mb-lg-0">
            <button
              class="btn btn-primary d-block p-3 m-auto"
              type="button"
              @click="exportarExcel()"
            >
              Descargar excel
            </button>
          </div>
          <div class="col-lg-4 col-12 mb-3 mb-lg-0">
            <button
              class="btn btn-primary d-block p-3 m-auto"
              type="button"
              @click="descargarImagenes()"
            >
              Descarga masiva de imagenes QR's
            </button>
          </div>
          <div class="col-lg-4 col-12 mb-0">
            <button
              class="btn btn-primary d-block p-3 m-auto"
              type="button"
              @click="descargarFormatosImpresion()"
            >
              Descarga masiva de formatos QR's
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
