<script>
    import FormularioTarjetaRegalo from '../../components/formularioTarjetaRegalo.vue';
export default {
    components:{
        FormularioTarjetaRegalo
    }
}
</script>
<template>
    <FormularioTarjetaRegalo urlSiguiente="/verificar" />
</template>
