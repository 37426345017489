<script >
import FormularioVerificacionTelefono from '../../components/formularioVerificacionTelefono.vue';
export default{
    components:{
        FormularioVerificacionTelefono
    }
}
</script>
<template>
    <FormularioVerificacionTelefono urlSiguiente="/buscar" />
</template>