<script>
    import FormularioBuscarTarjeta from '../../components/formularioBuscarTarjeta.vue';
export default {
    components:{
        FormularioBuscarTarjeta
    }
}
</script>
<template>
    <FormularioBuscarTarjeta urlSiguiente="/verificar"/>
</template>
