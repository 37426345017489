import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, dbRef } from "../../firebaseConfig.js";
import { child, get, getDatabase, onValue, ref, set, push, update, remove } from "firebase/database";
import { apiUsuarios } from "@/boot/axios.js";

const state = {
  usuarios: null,
  usuarioActual: null,
  num: 1,
  campañas: [],
};

// getters
const getters = {
  getUsuarioActual(state) {
    console.log(state.usuarioActual);
    return state.usuarioActual;
  },
};

// mutations
const mutations = {
  setUsuarioActual(state, usuario) {
    state.usuarioActual = usuario;
    console.log(state.usuarioActual);
  },
  setUsuarios(state, usuariosConsultados) {
    state.usuarios = usuariosConsultados;
    console.log(state.usuariosConsultados);
  },
  setCampañas(state, campañas) {
    state.campañas = campañas;
  },
};

// actions
const actions = {
  async iniciarSesion({ dispatch }, datosInicio) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        datosInicio.correo,
        datosInicio.contraseña
      );
      const user = userCredential.user;
      console.log(user);
      const errorBusqueda = await dispatch(
        "buscarUsuarioAuntenticado",
        user.uid
      );
      console.log(errorBusqueda);
      if (errorBusqueda) {
        return errorBusqueda;
      }
    } catch (error) {
      const errorCode = error.code;
      console.log(errorCode);
      return errorCode;
    }
  },

  async buscarUsuarioAuntenticado({ commit, dispatch }, uid) {
    try {
      console.log("Buscando Usuario");
      const snapshot = await get(
        child(
          dbRef,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/apps/app_wNrtPZCZWGaffe28L5i87a/members/${uid}`
        )
      );
      if (snapshot.exists()) {
        const usuarioEncontrado = snapshot.val();
        commit("setUsuarioActual", {
          uid,
          nombre: usuarioEncontrado.name,
          correo: usuarioEncontrado.email,
          rol: usuarioEncontrado.customData?.rol,
          rolApp: usuarioEncontrado.customData?.rolApp,
          division: usuarioEncontrado.customData?.Division,
        });

        if (usuarioEncontrado) {
          const rol = usuarioEncontrado.customData?.rol;
          const rolApp = usuarioEncontrado.customData?.rolApp;
          console.log(rol);
          // if (rol === "gerente") {
          //   dispatch("menu/setAdminMenu", true, { root: true });
          //   console.log("esAdmin");
        
          //     if (rolApp === "Administrador" || rolApp === "TI") {
          //       dispatch("menu/setAdminRolMenu", true, { root: true });
          //       console.log("esAdmin");
          //     } else {
          //       dispatch("menu/setAdminRolMenu", false, { root: true });
          //       console.log("NoesAdmin");
          //     }
          // } else if (rol === "supervisor") {
          //   dispatch("menu/setAdminMenu", false, { root: true });
          //   console.log("NoesAdmin");
        
          //   dispatch("menu/setAdminSupMenu", true, { root: true });
          //   console.log("esAdmin");
          // }

          if (rolApp && rolApp === "Administrador" || rolApp === "Sucursal") {
            dispatch("menu/setAdminRolMenu", true, { root: true });
            console.log("esAdmin");
          } else if (rol === "gerente" || rol === "superAdmin") {
            dispatch("menu/setAdminMenu", true, { root: true });
            console.log("esAdmin");              
          } else if (rol === "supervisor") {        
            dispatch("menu/setAdminSupMenu", true, { root: true });
            console.log("esAdmin");
          } else {
            dispatch("menu/setAdminMenu", false, { root: true });
          }
        
          return true;
        } else {
          commit("setUsuarioActual", null);
          await cerrarSesion();
          return "auth/not-found-db";
        }
      }
    } catch (error) {
      return error;
    }
  },

  async cerrarSesion() {
    try {
      await signOut(auth);
      console.log("Se cerró sesión");
    } catch (error) {
      console.log(error);
    }
  },

  async currentUser({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const unsuscribe = onAuthStateChanged(
        auth,
        (user) => {
          console.log(state.usuarioActual);
          console.log(user);
          if (!user) {
            commit("setUsuarioActual", null);
          }
          resolve(user);
        },
        (e) => reject(e)
      );
      unsuscribe();
    });
  },
  async recuperarContraseña({ commit }, correo) {
    try {
      console.log(correo);
      const res = await sendPasswordResetEmail(auth, correo);
      console.log(res);
      console.log("Se envio el correo");
      return true;
    } catch (error) {
      const errorCode = error.code;
      return errorCode;
    }
  },

  async consultarUsuarios({ commit, dispatch }, uid) {
    try {
      console.log("ConsultandoUSuarios0");
      const db = getDatabase();
      const starCountRef = ref(
        db,
        "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/apps/app_wNrtPZCZWGaffe28L5i87a/members/"
      );
      onValue(starCountRef, (snapshot) => {
        console.log("ConsultandoUSuarios");
        const usuariosConsultados = snapshot.val();
        console.log(usuariosConsultados);
        commit("setUsuarios", usuariosConsultados);
        return true;
      });

      // console.log("Buscando Usuario");
      // const snapshot = await get(
      //   child(
      //     dbRef,
      //     "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/apps/app_wNrtPZCZWGaffe28L5i87a/members/"
      //   )
      // );
      // if (snapshot.exists()) {
      //   const usuariosConsultados = snapshot.val();
      //   console.log(usuariosConsultados);
      //   commit("setUsuarios", usuariosConsultados);
      //   return true;
      // } else {
      // }
    } catch (error) {
      return error;
    }
  },

  async crearNuevoUsuario({ commit }, dataUsuario) {
    try {
      const { correo, contraseña, nombre } = dataUsuario;
      console.log(dataUsuario);
      const res = await apiUsuarios({
        method: "POST",
        url: "/crear-usuario",
        data: {
          correo,
          contraseña,
          nombre,
        },
      });
      console.log(res);

      return res.data.data.uid;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async guardarDatosNuevoUsuario({ commit }, objetoDataNuevoUsuario) {
    const { uid, nombre, correo, contraseña, rol, sucursal, rolApp } =
      objetoDataNuevoUsuario;
    try {
      const db = getDatabase();
      set(
        ref(
          db,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/apps/app_wNrtPZCZWGaffe28L5i87a/members/${uid}`
        ),
        {
          email: correo,
          hashedPassword: contraseña,
          name: nombre,
          customData: {
            Division: sucursal,
            rol: rol,
            rolApp: rolApp
          },
        }
      );
    } catch (error) {
      throw {
        msg: "Error al guardar usuario Firebase",
        code: "SKE-115",
      };
    }
  },

  async actualizarDatosPromotor({ commit }, objetoDataPromotor) {
    const { nombre, division, uid } = objetoDataPromotor;
    console.log(nombre);
    console.log(division);
    console.log(uid);
    try {
      const db = getDatabase();

      const postData = {
        Division: division,
        rol: "promotor",
        promotoraName: nombre,
      };
      
      const updates = {};
      updates[`projects/proj_tCJWQHSHNf7WoMu7r64pUJ/apps/app_wNrtPZCZWGaffe28L5i87a/members/${uid}/customData`] = postData;

      update(ref(db), updates);

    } catch (error) {
      console.log(error);
      throw {
        msg: "Error al guardar data promotor Firebase",
        code: "SKE-118",
      };
    }
  },

  async consultarAccesosEmpleado({ commit }, dataUsuario) {
    try {
      const { usuario, contraseña } = dataUsuario;
      console.log(dataUsuario);
      const res = await apiUsuarios({
        method: "POST",
        url: "/consultar-accesos-usuario",
        data: {
          usuario,
          contraseña,
        },
      });
      console.log(res);

      return res.data.data.tbl_users;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async guardarCampaña({ commit }, objetoDataCampaña) {
    const {
      descripcionActivacion,
    } = objetoDataCampaña;
    try {
      const db = getDatabase();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("Usuario no autenticado");
      }
      const idUsuario = user.uid;

      const fechaActual = new Date();
      const dia = fechaActual.getDate();
      const mes = fechaActual.getMonth() + 1;
      const año = fechaActual.getFullYear();
      const fecha = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${año}`;

      const campañasRef = ref(
          db, "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/CampaniasTarjetaClub"
        );

        const newCampañaRef = await push(
          campañasRef,
          {
            descripcionActivacion,
            fecha,
            idUsuario,
          }
        );
        const idCampaña = newCampañaRef.key;
        return idCampaña;
    } catch (error) {
      console.error("Error al agregar la campaña a Firebase:", error);
    }
  },

  async obtenerCampañas({ commit }) {
    try {
      const db = getDatabase();
      const campañasRef = ref(
        db, "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/CampaniasTarjetaClub"
      );
      onValue(campañasRef, (snapshot) => {
      const campañas = [];
      snapshot.forEach((childSnapshot) => {
        const campaña = childSnapshot.val();
        const idCampaña = childSnapshot.key;
        campaña.id = idCampaña;
        campañas.push(campaña);
      });
      commit("setCampañas", campañas);
      });
    } catch (error) {
      console.error("Error al obtener las campañas de Firebase:", error);
      throw error;
    }
  },

  async eliminarCampaña({ commit }, idCampaña) {
    try {
      const db = getDatabase();
      const campañaRef = ref(
        db, `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/CampaniasTarjetaClub/${idCampaña}`
      );
      await remove(campañaRef);
    } catch (error) {
      console.error("Error al eliminar la campaña de Firebase:", error);
      throw error;
    }
  },
  
  async actualizarCampaña({ commit }, { idCampaña, nuevaDescripcion }) {
    try {
      const db = getDatabase();
      await update(ref(
        db, `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/CampaniasTarjetaClub/${idCampaña}`),
        {
          descripcionActivacion: nuevaDescripcion
        });
      return true;
    } catch (error) {
      console.error("Error al actualizar la descripción:", error);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
