<script>
    import FormularioAltaTarjeta from '../../components/formularioAltaTarjeta.vue';
export default {
    components:{
        FormularioAltaTarjeta
    }
}
</script>
<template>
    <FormularioAltaTarjeta urlSiguiente="/cliente/verificar-correo" activacion="true" />
</template>
