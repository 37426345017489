<script>
    import FormularioLogin from '../../components/formularioLogin.vue';
    export default {
        components:{
            FormularioLogin
        }
    }
</script>
<template>
    <FormularioLogin />
</template>
<style>

</style>