<template>
    <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 p-0 footer-left">
              <p class="mb-0">Copyright © 2023 SuperKompras. All rights reserved.</p>
            </div>
            <div class="col-md-6 p-0 footer-right">
              
            </div>
          </div>
        </div>
      </footer>
</template>