import { apiComercio } from "@/boot/axios";
import moment from "moment/moment";
import {
  getDatabase,
  ref,
  onValue,
  update,
  remove,
  set,
  query,
  orderByKey,
  startAt,
  endAt,
  orderByChild,
} from "firebase/database";

const state = {
  secciones: {},
  seccion: {},
  pedidos: {},
  productosSeleccionCasa: {},
  productosAlgolia: [],
  pedidosMetricas: {},
  sucursalesComercio: {}
};

// getters
const getters = {};

// mutations
const mutations = {
  setSecciones(state, secciones) {
    state.secciones = secciones;
  },
  setSeccion(state, dataSeccion) {
    state.seccion = dataSeccion;
  },
  setPedidos(state, pedidos) {
    state.pedidos = pedidos;
  },
  setProductosAlgolia(state, productos) {
    state.productosAlgolia = productos;
  },
  setproductosSeleccionCasa(state, productos) {
    state.productosSeleccionCasa = productos;
  },
  setPedidosMetricas(state, pedidos) {
    state.pedidosMetricas = pedidos;
  },
  setSucursalesComercio(state, sucursales){
    state.sucursalesComercio = sucursales;
  }
};

// actions
const actions = {
  async consultarSecciones({ commit }) {
    try {
      const dbRef = getDatabase();
      const seccionesRef = ref(
        dbRef,
        "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Secciones"
      );
      await onValue(seccionesRef, (snapshot) => {
        const data = snapshot.val();
        commit("setSecciones", data);
      });
    } catch (error) {
      console.log(error);
    }
  },
  async consultarUnaSeccion({ commit }, id) {
    try {
      const dbRef = getDatabase();
      const seccionesRef = ref(
        dbRef,
        `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Secciones/${id}`
      );
      await onValue(seccionesRef, (snapshot) => {
        const data = snapshot.val();

        commit("setSeccion", data);
      });
    } catch (error) {
      console.log(error);
    }
  },
  async actualizarNombreSeccion({ commit }, datosSeccion) {
    try {
      console.log(datosSeccion);
      const db = getDatabase();

      // A post entry.
      const nuevoNombre = datosSeccion.nombre;
      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates[
        "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Secciones/" +
          datosSeccion.id +
          "/Nombre"
      ] = nuevoNombre;

      console.log(updates);

      const res = await update(ref(db), updates);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  },
  async removerProductoSeccion({ commit }, rutaProducto) {
    console.log(rutaProducto);
    try {
      const db = getDatabase();
      const refProducto = ref(
        db,
        `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Secciones/${rutaProducto.seccion}/Productos/${rutaProducto.idProducto}`
      );

      remove(refProducto).then(() => {
        console.log("Se elimino");
      });
    } catch (error) {
      console.log(error);
    }
  },
  async consultarProductosAlgolia({ commit }, textoBusqueda) {
    try {
      const res = await apiComercio({
        method: "POST",
        url: "/buscarProductos",
        data: {
          textoBusqueda,
        },
      });
      console.log(res.data.data[0]);
      commit("setProductosAlgolia", res.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async agregarProductoSeccion({ commit }, dataProducto) {
    try {
      console.log(dataProducto);
      const db = getDatabase();
      const res = await set(
        ref(
          db,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Secciones/${dataProducto.seccion}/Productos/${dataProducto.idProducto}`
        ),
        {
          ProductoID: dataProducto.idProducto,
          Data: dataProducto.data,
        }
      );

      return true;
    } catch (error) {
      console.log(error);
    }
  },
  async consultarPedidos({ commit }, fechasFiltradoSucursal) {
    try {
      let campoFiltrado = "TimeSolicitud";
      const dbRef = getDatabase();
      const pedidosRef = ref(
        dbRef,
        "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Pedidos"
      );

      console.log(fechasFiltradoSucursal);

      const newFechaInicio = moment(
        moment(fechasFiltradoSucursal[0]).format("YYYY-MM-DD")
      ).format("x");

      let fechaInicio = parseInt(newFechaInicio);

      const newFechaFin = moment(
        moment(fechasFiltradoSucursal[1]).format("YYYY-MM-DD")
      ).format("x");

      let fechaFin = parseInt(newFechaFin) + 86399999;

      if(fechasFiltradoSucursal[2]){
        console.log("Fechasssssssssssssss");
        fechaInicio = fechasFiltradoSucursal[2] + fechaInicio;
        console.log(fechaInicio);
        fechaFin = fechasFiltradoSucursal[2] + fechaFin;
        console.log(fechaFin);
        campoFiltrado = "divisionTimestamp";

      }

      console.log(fechaInicio);
      console.log(fechaFin);



      const queryPedidosRef = query(
        pedidosRef,
        orderByChild(campoFiltrado),
        startAt(fechaInicio),
        endAt(fechaFin)
      );

      await onValue(queryPedidosRef, (snapshot) => {
        console.log(snapshot);
        const data = snapshot.val();

        commit("setPedidos", data);
        console.log(data);
      });
    } catch (error) {
      console.log(error);
    }
  },
  async consultarProductosSeleccionCasa({ commit }) {
    try {
      const dbRef = getDatabase();
      const seccionesRef = ref(
        dbRef,
        `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Seccion_uno/SEC4/Productos`
      );
      await onValue(seccionesRef, (snapshot) => {
        const data = snapshot.val();

        commit("setproductosSeleccionCasa", data);
      });
    } catch (error) {
      console.log(error);
    }
  },
  async consultarPedidosMetricas({ commit }, objetoFiltradoPedido) {
    try {
      console.log(objetoFiltradoPedido.fechaInicioPedido);
      const {rutaPedidos, fechaInicioPedido, fechaFinPedido} = objetoFiltradoPedido;
      const dbRef = getDatabase();
      const pedidosRef = ref(
        dbRef,
        rutaPedidos
      );


      const newFechaInicio = moment(
        moment(fechaInicioPedido).format("YYYY-MM-DD")
      ).format("x");

      const fechaInicio = parseInt(newFechaInicio);

      const newFechaFin = moment(
        moment(fechaFinPedido).format("YYYY-MM-DD")
      ).format("x");

      const fechaFin = parseInt(newFechaFin) + 86400000;

      const queryPedidosRef = query(
        pedidosRef,
        orderByChild("TimeSolicitud"),
        startAt(fechaInicio),
        endAt(fechaFin)
      );

      await onValue(queryPedidosRef, (snapshot) => {
        console.log(snapshot);
        const data = snapshot.val();

        commit("setPedidos", data);
        
      });
    } catch (error) {
      console.log(error);
    }
  },
  async consultarSucursalesComercio({ commit }) {
    try {
      const dbRef = getDatabase();
      const seccionesRef = ref(
        dbRef,
        `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/Tiendas`
      );
      await onValue(seccionesRef, (snapshot) => {
        const data = snapshot.val();

        commit("setSucursalesComercio", data);
      });
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
