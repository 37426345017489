<script>
import FormularioVerificacionTarjeta from '../../components/formularioVerificacionTarjeta.vue';

export default{
    components:{
        FormularioVerificacionTarjeta
    }
}
</script>
<template>
    <FormularioVerificacionTarjeta urlSiguiente="/verificar-correo" :nombreEditable="true" :recomendado="true"/>
</template>