<script>
/* eslint-disable */
import SeccionComercio from "../../../components/seccionComercio.vue";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
        arregloSecciones:[]
    };
  },
  created() {
    this.consultarSecciones();
},
components: {
  SeccionComercio,
},
  computed: {
    ...mapState("comercio", ["secciones"]),
  },
  methods: {
    ...mapActions("comercio", ["consultarSecciones"]),
    crearArregloSecciones(){
        this.arregloSecciones=[];
        let contador = 0;
        let arregloAuxiliar = [];
        
        for (const [id, seccion] of Object.entries(this.secciones)) {
          let objetoSeccion = {
                id: id,
                nombre: seccion.Nombre,
                totalProductos: Object.values(seccion.Productos).length
            }
            arregloAuxiliar[contador] = objetoSeccion;
            contador++;
  
            if(contador == 2){
                this.arregloSecciones.push(arregloAuxiliar);
                contador = 0;
                arregloAuxiliar = [];
            }
        }

        if(arregloAuxiliar.length != 0 && contador != 0){
            this.arregloSecciones.push(arregloAuxiliar);
        }
    }
  },
  watch: {
    secciones(nuevasSecciones) {
      this.crearArregloSecciones();
    },
  },
};
</script>
<template>
    
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Secciones</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="col-xl-9 col-lg-12">
    
      <div class="row" v-for="arreglo in arregloSecciones ">
        <SeccionComercio v-for="seccion in arreglo" :seccion="seccion"> </SeccionComercio>
      </div>
    </div>
  </div>
</template>
