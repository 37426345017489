import { createApp } from 'vue'
import App from './App.vue'
import './firebaseConfig'; 
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/app.scss"
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'vue3-tour/dist/vue3-tour.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; 
import 'vue-rate/dist/vue-rate.css'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import 'v-calendar/dist/style.css';

import VueApexCharts from "vue3-apexcharts";
import Datepicker from '@vuepic/vue-datepicker';
import VueFeather from "vue-feather";
import Breadcrumbs from './layout/breadCrumbs.vue';
import VCalendar from 'v-calendar';
import Toaster from "@meforma/vue-toaster";
import VueSweetalert2 from 'vue-sweetalert2';
import VueNumber from "vue-number-animation";

import { createI18n } from 'vue-i18n'
import English from "./locales/en.json"
import Español from "./locales/es.json"
import Deutsch from "./locales/de.json"
import Français from "./locales/fr.json"
import Português from "./locales/pt.json"
import 简体中文 from "./locales/cn.json"
import لعربية from "./locales/ae.json"
import German from "./locales/ge.json"
import Russian from "./locales/ru.json"
import Arabic from "./locales/ar.json"
const i18n = createI18n({ legacy: false, // you must specify 'legacy: false' option
  locale: 'ja',
  messages: {
   English: English,
    Español: Español,
    Deutsch: Deutsch,
    Français: Français,   
   Português: Português,
    简体中文: 简体中文,
    لعربية: لعربية,
    German: German,
    Russian: Russian,
    Arabic: Arabic
    }
  })

createApp(App)
.use(store)
.use(VueSweetalert2)
.use(VueApexCharts)
.use(router)
.use(VCalendar, {})
.use(i18n)
.use(Toaster)
.use(VueNumber)
.component(VueFeather.name, VueFeather)
.component('Breadcrumbs', Breadcrumbs)
.component('Datepicker', Datepicker)
.mount('#app')
