<script>
/* eslint-disable */
import imageMixin from "@/mixins/imageMixin";
export default {
  mixins: [imageMixin],
  props: [
    "data",
    "headers",
    "search",
    "campos",
    "listaEstatus",
    "elementosPagina",
    "listaBotones",
  ],
  data() {
    return {
      elementsPerPage: 10,
      currentPage: 1,
      ascending: false,
      sortColumn: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterQuery: "",
      allData: [],
    };
  },
  mounted() {
    if (this.elementosPagina) {
      console.log(this.elementosPagina);
      this.elementsPerPage = this.elementosPagina;
    }
  },
  watch: {
    filterQuery(search) {
      var filteredData = this.productlist.filter((row) => {
        return (
          row.productName.toLowerCase().includes(search.toLowerCase()) ||
          row.productSubName.toLowerCase().includes(search.toLowerCase()) ||
          row.startData.toLowerCase().includes(search.toLowerCase())
        );
      });
      search == ""
        ? (this.allData = this.productlist)
        : (this.allData = filteredData);
    },
  },
  computed: {
    columns() {
      if (this.allData.length == 0) {
        return [];
      }
      return Object.keys(this.allData[0]);
    },
    get_rows() {
      var start = (this.currentPage - 1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      return this.allData.slice(start, end);
    },
  },
  methods: {
    removeProduct: function (elemento) {
      this.$emit("removerElemento", elemento);
    },
    addProduct: function (elemento) {
      this.$emit("agregarElemento", elemento);
    },
    viewDetail: function (elemento) {
      this.$emit("verDetalleElemento", elemento);
    },
    entregarTarjetaSk: function (elemento) {
      this.$emit("entregarTarjeta", elemento);
    },
    editarUsuario: function (elemento) {
      this.$emit("editarDatosEmpleado", elemento);
    },
    llamarFuncionesMultiplesQrs: function (elemento, tipoFuncion) {
      console.log(tipoFuncion);
      console.log(elemento);
      switch (tipoFuncion) {
        case "1":
          console.log(elemento);
          this.$emit("descargarQrFolio", elemento);
          break;
        case "2":
          
          break;
        case "3":
          this.$emit("imprimirFormato", elemento);
          break;
    
      }
    },
    num_pages() {
      return Math.ceil(this.allData.length / this.elementsPerPage);
    },
    change_page(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.allData = this.data;
  },
  watch: {
    data(nuevaData) {
      this.allData = nuevaData;
    },
    get_rows(newRows) {
      if (newRows.length < 1) {
        this.change_page(1);
      }
    },
  },
};
</script>
<template>
  <div class="table-responsive theme-scrollbar product-table">
    <form class="dataTables_wrapper no-footer">
      <div class="mb-3 row justify-content-start" v-if="search">
        <label
          for="table-complete-search"
          class="col-xs-3 col-sm-auto col-form-label"
          >Search:</label
        >
        <div class="col-xs-3 col-sm-auto">
          <input
            id="table-complete-search"
            type="text"
            class="form-control"
            name="searchTerm"
            v-model="filterQuery"
          />
        </div>
      </div>
      <table
        class="display table-striped table-hover table-bordered table"
        id="basic-1"
      >
        <thead>
          <tr>
            <th v-for="header in headers" class="sorting_asc" scope="col">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd" v-for="(row, index) in get_rows" :key="row">
            <td
              v-for="campo in campos"
              :class="[campo.tipo == 'boton' ? '' : 'sorting_1 text-center']"
            >
              <div v-if="campo.tipo === 'imagen'">
                <img :src="row[campo.clave]" />
              </div>
              <div v-if="campo.tipo === 'boton'">
                <button
                  v-if="campo.funcion === 'remover'"
                  class="btn btn-danger btn-xs me-2"
                  type="button"
                  data-original-title="btn btn-danger btn-xs"
                  @click="removeProduct(row)"
                  title=""
                >
                  Remover
                </button>
                <button
                  v-if="campo.funcion === 'agregar'"
                  class="btn btn-primary btn-xs me-2"
                  type="button"
                  data-original-title="btn btn-danger btn-xs"
                  @click="addProduct(row)"
                  title=""
                >
                  Agregar
                </button>
                <button
                  v-if="campo.funcion === 'detalle'"
                  class="btn btn-primary btn-xs me-2"
                  style="
                    background-color: #172554 !important;
                    border-color: #172554 !important;
                  "
                  type="button"
                  data-original-title="btn btn-danger btn-xs"
                  @click="viewDetail(row)"
                  title=""
                >
                  Ver detalle
                </button>
                <button
                  v-if="campo.funcion === 'entregarTarjeta'"
                  class="btn btn-primary btn-xs me-2"
                  style="
                    background-color: #172554 !important;
                    border-color: #172554 !important;
                  "
                  type="button"
                  data-original-title="btn btn-danger btn-xs"
                  @click="entregarTarjetaSk(row)"
                  title=""
                >
                  Entregar Tarjeta
                </button>
                <button
                  v-if="campo.funcion === 'edicionRapida'"
                  class="btn btn-primary btn-xs me-2"
                  style="
                    background-color: #172554 !important;
                    border-color: #172554 !important;
                  "
                  type="button"
                  data-original-title="btn btn-danger btn-xs"
                  @click="editarUsuario(row)"
                  title=""
                >
                  Edición rápida
                </button>
              </div>
              <div v-if="campo.tipo === 'texto'">
                <h6 v-if="campo.bold">{{ row[campo.clave] }}</h6>
                <span v-else>{{ row[campo.clave] }}</span>
              </div>
              <div v-if="campo.tipo === 'estatus'">
                <div v-for="elementoLista in listaEstatus">
                  <div
                    class="card m-0"
                    v-if="row[campo.clave] == elementoLista.estatus"
                  >
                    <div
                      class="card-body rounded py-2 px-3"
                      :style="{ 'background-color': elementoLista.color }"
                    >
                      <span style="color: white">
                        {{ row[campo.clave] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="campo.tipo === 'botones'" class="d-flex justify-content-between gap-3">
                <button
                  v-for="boton in listaBotones"
                  :class="[boton.claseBoton]"
                  type="button"
                  @click="llamarFuncionesMultiplesQrs(row, boton.tipoFuncion)"
                >
                  {{ boton.titulo }}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ul
        class="pagination pagination-primary p-2 d-flex justify-content-end mt-3"
        v-if="num_pages() > 0"
      >
        <li class="page-item" v-if="currentPage != 1">
          <a class="page-link" @click="change_page(1)">&laquo;</a>
        </li>
        <li class="page-item" v-if="currentPage != 1">
          <a class="page-link" @click="change_page(currentPage - 1)"
            >Anterior</a
          >
        </li>
        <li
          v-if="currentPage - 2 > 0"
          :class="[currentPage - 2 - 1 < 1 ? 'page-item' : '']"
          v-on:click="
            currentPage - 2 - 1 < 1 ? change_page(currentPage - 2) : null
          "
          :key="currentPage - 2"
        >
          <a class="page-link">{{
            currentPage - 2 - 1 < 1 ? currentPage - 2 : "..."
          }}</a>
        </li>
        <li
          v-if="currentPage - 1 > 0"
          class="page-item"
          v-on:click="change_page(currentPage - 1)"
          :key="currentPage - 1"
        >
          <a class="page-link">{{ currentPage - 1 }}</a>
        </li>
        <li
          class="page-item active"
          v-on:click="change_page(currentPage)"
          :key="currentPage"
        >
          <a class="page-link">{{ currentPage }}</a>
        </li>
        <li
          v-if="currentPage + 1 <= num_pages()"
          class="page-item"
          v-on:click="change_page(currentPage + 1)"
          :key="currentPage + 1"
        >
          <a class="page-link">{{ currentPage + 1 }}</a>
        </li>
        <li
          v-if="currentPage + 2 <= num_pages()"
          :class="[num_pages() - (currentPage + 2) < 1 ? 'page-item' : '']"
          v-on:click="
            num_pages() - (currentPage + 2) < 1
              ? change_page(currentPage + 2)
              : null
          "
          :key="currentPage + 2"
          disabled
        >
          <a class="page-link">{{
            num_pages() - (currentPage + 2) < 1 ? currentPage + 2 : "..."
          }}</a>
        </li>
        <!-- <li
          class="page-item"
          v-for="i in num_pages()"
          :key="i"
          v-bind:class="[i == currentPage ? 'active' : '']"
          v-on:click="change_page(i)"
        >
          <a class="page-link">{{ i }}</a>
        </li> -->
        <li class="page-item" v-if="currentPage != num_pages()">
          <a class="page-link" @click="change_page(currentPage + 1)"
            >Siguiente</a
          >
        </li>
        <li class="page-item" v-if="currentPage != num_pages()">
          <a class="page-link" @click="change_page(num_pages())">&raquo;</a>
        </li>
      </ul>
    </form>
  </div>
</template>
