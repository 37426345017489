<template>
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <sidebar />
    </div>
    <div class="left-header col horizontal-wrapper ps-0">

    </div>
    <div class="nav-right col-6 pull-right right-header p-0">
      <ul class="nav-menus">
        <li>
          
        </li>
        <li>
          <modeView />
        </li>
        <li class="maximize">
          <maximizeView />
        </li>
        <li class="profile-nav onhover-dropdown">
          <profileView />
        </li>
      </ul>
    </div>
  </div>
  {{ getUsuarioActual }}
</template>
<script>
// import { mapGetters } from "vuex"
import searchInput from "./searchInput.vue"
import sidebar from "./sidebarView.vue"
import searchView from "./searchView.vue"
import modeView from "./modeView.vue"
import maximizeView from "./maximizeView.vue"
import profileView from "./profileView.vue"

export default {
  components: {
    sidebar,
    modeView,
    maximizeView,
    profileView
  },
  data() {
    return {
      urlLogoUsuario:"",
      filtered: false,
    };
  },
  computed:{
    // ...mapGetters("autenticacion",["getUsuarioActual"]),
  },
  // watch:{
  //   this.getUsuarioActual((nuevoUsuario) => {
  //   if(nuevoUsuario?.correo){
  //       this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${nuevoUsuario?.nombre}&background=random`
  //   }else{
  //       this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${nuevoUsuario?.correo}&background=random`
  //   }
  
  // })
  // },
  methods: {
    collapseFilter() {
      this.filtered = !this.filtered;
    },
  },
  
}
</script>