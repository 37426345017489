<script>
import FormularioGenerarQrs from "@/components/formularioGenerarQrs.vue";
export default {
  components: {
    FormularioGenerarQrs,
  },
};
</script>
<template>
  <FormularioGenerarQrs />
</template>
