<script>
import { mapActions } from 'vuex';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default{
  data(){
    return{
      correo:"",
      mensajeError:""
    }
  },
  created() {
  },  
  methods:{
    ...mapActions("autenticacion", ["recuperarContraseña"]),
    async enviarCorreoRecuperarContraseña(){
      try {
        this.mensajeError = "";
        const respuestaRecuperacion = await this.recuperarContraseña(this.correo);
        
        console.log(respuestaRecuperacion);

        switch (respuestaRecuperacion) {
          case true:
            toast.success(" ! Mensaje de recuperación enviado! ", {
                hideProgressBar: true,
                autoClose: 2000,
                theme: "colored",
            });
            break;
          case "auth/invalid-email":
            this.mensajeError = "Por favor, introduzca un correo válido"
            break;
          case "auth/missing-email":
            this.mensajeError = "Por favor, proporcione un correo"
            break;
          case "auth/user-not-found":
            this.mensajeError = "No existe un usuario asignado a esta cuenta"
            break;
          default:
            this.mensajeError = "Error inesperado, intentelo más tarde"
            break;
        }

        if (this.mensajeError) {
          this.$swal({
            icon: 'warning',
            type: 'warning',
            title: 'Oops...',
            text: this.mensajeError
          })
        }

        
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div><a class="logo"><router-link to="/login"><img class="img-fluid for-light" src="@/assets/images/logo-SK.png"
                    alt="looginpage"></router-link> </a></div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="enviarCorreoRecuperarContraseña()">
                <h4>Restablece tu contraseña</h4>
                <div class="form-group">
                  <label class="col-form-label">Introduce tu correo electrónico</label>
                  <input class="form-control" type="email" name="correo" placeholder="Correo" v-model.trim="correo">
                </div>
                <div class="form-group mb-0">
                  
                  <button class="btn btn-primary btn-block w-100 mt-3" type="submit">Enviar </button>
                </div>
                <p class="mt-4 mb-0 text-center">¿Ya tienes una contraseña?<router-link class="ms-2"
                    to="/login">Iniciar sesión</router-link></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>