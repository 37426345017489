import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyCGlstIABJxdA4k36VixzhdyvT1Q3Vibu8",
    authDomain: "super-kompras.firebaseapp.com",
    databaseURL: "https://super-kompras.firebaseio.com",
    projectId: "super-kompras",
    storageBucket: "super-kompras.appspot.com",
    messagingSenderId: "401245304429",
    appId: "1:401245304429:web:e5eb28c1c0b51a0053f164"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const dbRef = ref(database);

export { auth, dbRef }