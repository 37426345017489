<template>
  <div class="col-sm-6">
    <div class="card social-widget-card">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h4>{{ seccion.nombre }}</h4>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col d-flex justify-content-between">
            <div class="text-center">
              <h6>Total de artículos</h6>
              <h5 class="counter mb-0 font-primary mt-1">
                <number
                  class="bold counter"
                  ref="number1"
                  :from="0"
                  :to="seccion.totalProductos"
                  :duration="2"
                  :delay="0"
                  easing="Power1.easeOut"
                />
              </h5>
            </div>
            <div class="h-100">
                <button class="btn btn-light px-2 py-2 rounded" @click="redirigirEdicionSeccion()">
                    <i class="icofont icofont-2x icofont-ui-edit"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["seccion"],
  data() {
    return {};
  },
  methods:{
    redirigirEdicionSeccion(){
      this.$router.push(`/detalles-seccion/${this.seccion.id}`)
    }
  }
};
</script>
