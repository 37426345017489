<script>
import { mapActions, mapState } from "vuex";
import TablaLista from "../../../components/tablaLista.vue";
export default {
  data() {
    return {
      idSeccion: "",
      nombreSeccion: "",
      headersTabla: [
        "ID",
        "Imagen",
        "Nombre",
        "Categoría",
        "Precio",
        "Precio promoción",
        "Acciones",
      ],
      campos: [
        { tipo: "texto", clave: "id" },
        { tipo: "imagen", clave: "imagen" },
        { tipo: "texto", clave: "nombre", bold: true },
        { tipo: "texto", clave: "categoria" },
        { tipo: "texto", clave: "precio" },
        { tipo: "texto", clave: "precioPromocion" },
        { tipo: "boton", funcion: "agregar" },
      ],
      productos: [],
      nombreProducto: "",
    };
  },
  components: {
    TablaLista,
  },
  created() {
    this.idSeccion = this.$route.params.id;
    this.nombreSeccion = this.$route.params.nombreSeccion;
  },
  computed: {
    ...mapState("comercio", ["productosAlgolia"]),
  },
  methods: {
    ...mapActions("comercio", [
      "consultarProductosAlgolia",
      "agregarProductoSeccion",
    ]),
    async agregarElementoSeccion(elemento) {
      let dataProductoAgregar = this.productosAlgolia.find((producto) => {
        return producto.objectID == elemento.id;
      });

      delete dataProductoAgregar["_highlightResult"];

      const respuestaProductoAgregado = await this.agregarProductoSeccion({
        seccion: this.idSeccion,
        idProducto: elemento.id,
        data: dataProductoAgregar,
      });

      if (respuestaProductoAgregado == true) {
        this.$swal({
          title: "¡Producto agregado con éxito!",
          text: `${elemento?.nombre}`,
          icon: "success",
          confirmButtonText: "Continuar",
          confirmButtonColor: "#10B981"
        })
      }

    },
  },
  watch: {
    productosAlgolia(nuevosProductos) {
      if (!nuevosProductos) {
        this.productos = [];
      } else {
        this.productos = nuevosProductos;
        this.productos = this.productos.map((producto) => {
          return {
            id: producto.objectID,
            nombre: producto.DESCRIPCION,
            categoria: producto.CATEGORIAAPP,
            imagen: producto.URLS512,
            precio: "$" + producto.PRECIO_7127,
            precioPromocion: producto.PRECIOOFERTA_7127
              ? "$" + producto.PRECIOOFERTA_7127
              : "-",
          };
        });
      }
    },
  },
};
</script>
<template>
  <Breadcrumbs main="Secciones" :middle="nombreSeccion" title="Agregar productos" />
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row d-flex align-items-center">
          <div class="col-lg-10 col-sm-12 mb-3">
            <input
              class="form-control"
              id="busquedaProductos"
              type="text"
              placeholder="Nombre de producto a agregar"
              v-model.trim="nombreProducto"
            />
          </div>
          <div class="col-lg-2 col-sm-12 mb-3 d-flex justify-content-end">
            <button
              class="btn btn-primary"
              type="button"
              @click="consultarProductosAlgolia(nombreProducto)"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <TablaLista
          :data="productos"
          :headers="headersTabla"
          :search="false"
          :campos="campos"
          @agregarElemento="agregarElementoSeccion"
        ></TablaLista>
      </div>
    </div>
  </div>
</template>
