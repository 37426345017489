<template>
  <div class="error-wrapper">
    <div class="container">
      <div class="svg-wrraper">
        <errorPage1 />
      </div>
      <div class="col-md-8 offset-md-2">
        <h3>Acceso Restringido</h3>
        <p class="sub-content">
          
No dispones de los permisos necesarios para acceder a la página que deseas. Te recomendamos verificar tus permisos o contactar al administrador del sitio para obtener la autorización correspondiente.
        </p>
        <router-link class="btn btn-primary" to="/buscar"> Ir a la página principal </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import errorPage1 from "@/svg/errorPage1.vue";
export default {
  components: {
    errorPage1,
  },
};
</script>
