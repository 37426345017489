<script>
    import FormularioVerificacionCorreo from '../../components/formularioVerificacionCorreo.vue';
    export default{
        components:{
            FormularioVerificacionCorreo
        }
    }
</script>
<template>
    <FormularioVerificacionCorreo urlSiguiente="/verificar-telefono"/>
</template>