<template>
  <nav class="navbar" style="background-color: #283879;">
    <div class="container d-flex justify-content-center">
      <a class="navbar-brand" href="#">
        <img
          src="../../src/assets/images/Header_pagina_preregistro.png"
          alt="Super kompras"
          
          height="40"
        />
      </a>
    </div>
  </nav>
  <div class="container mt-5">
    <router-view></router-view>
  </div>
</template>
<script></script>
<style></style>
