<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      folioInicial: "",
      folioFinal: "",
      montoActivacion: "",
      campaña: "",
      fechasVigencia: [],
      dimensionesQr: "128x128",
      campañasActuales: [],
      arregloFolios: [],
    };
  },
  created() {
    this.mostrarAlertaNoDisponible();
    this.obtenerCampañasQrs();
  },
  computed: {
    ...mapState("validacion", ["campañas"]),
  },
  methods: {
    ...mapActions("validacion", ["obtenerCampañasQrs", "guardarFoliosQrs"]),
    mostrarAlertaNoDisponible() {
      this.$swal({
        icon: "warning",
        type: "warning",
        title: "Esta sección no se encuentra disponible actualmente",
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if(result.isConfirmed){
          this.$router.push("/");
        } 
      });
    },
    generarQrs() {
      for (
        let indexFolio = this.folioInicial;
        indexFolio <= this.folioFinal;
        indexFolio++
      ) {
        this.arregloFolios.push({
          folio: indexFolio,
          url: `https://api.qrserver.com/v1/create-qr-code/?data=https://validaciontarjetaclub.superkompras.com.mx/cliente/activacion-tarjeta/${indexFolio}&size=${this.dimensionesQr}&format=png`,
        });
      }
      this.guardarFoliosQrs(this.arregloFolios);
    },
    validarFormularioQrs() {
      const errores = [];

      const formatosValidos13Caracteres = [71, 75, 77];
      const formatosValidos12Caracteres = [70];
      const longitudFolioInicial = this.folioInicial.toString().length;
      const longitudFolioFinal = this.folioFinal.toString().length;
      if (this.folioInicial == "") {
        errores.push({ msg: "Proporcione un folio inicial" });
      } else if (isNaN(this.folioInicial)) {
        errores.push({ msg: "Proporcione un folio inicial válido 1" });
      } else if (
        longitudFolioInicial != 12 &&
        longitudFolioInicial != 13
      ) {
        errores.push({ msg: "Proporcione un folio inicial válido 2"+ longitudFolioInicial + "" + longitudFolioInicial != 12 &&
        longitudFolioInicial != 13 });
      } else if (
        longitudFolioInicial == 13 &&
        !formatosValidos13Caracteres.includes(parseInt(this.folioInicial.toString().slice(0, 2)))
      ) {
        errores.push({ msg: "Proporcione un folio inicial válido 3" });
      } else if (
        longitudFolioInicial == 12 &&
        !formatosValidos12Caracteres.includes(parseInt(this.folioInicial.toString().slice(0, 2)))
      ) {
        errores.push({ msg: "Proporcione un folio inicial válido 4" });
      } else {
        if (this.folioFinal == "") {
          errores.push({ msg: "Proporcione un folio final" });
        } else if (isNaN(this.folioInicial)) {
          errores.push({ msg: "Proporcione un folio final válido" });
        } else if (
          longitudFolioFinal != 12 &&
          longitudFolioFinal != 13
        ) {
          errores.push({ msg: "Proporcione un folio final válido" });
        } else {
          if (
            this.folioInicial.toString().slice(0, 2) != this.folioFinal.toString().slice(0, 2) ||
            this.folioFinal < this.folioInicial ||
            this.longitudFolioInicial != this.longitudFolioFinal
          ) {
            errores.push({ msg: "Proporcione un rango de folios válido" });
          }
        }
      }

      if (this.montoActivacion == "") {
        errores.push({ msg: "Proporcione un monto de activación" });
      }
      if (this.campaña == "") {
        errores.push({ msg: "Proporcione un nombre de campaña" });
      }
      if (
        this.fechasVigencia == [] ||
        this.fechasVigencia[0] == null ||
        this.fechasVigencia[1] == null
      ) {
        errores.push({ msg: "Proporcione un rango de fechas de vigencia" });
      }
      if (this.dimensionesQr == "") {
        errores.push({ msg: "Proporcione las dimensiones del QR" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonDesactivado = false;
      } else {
        this.generarQrs();
      }
    },
  },
  watch: {
    campañas(nuevasCampañas) {
      for (const [key, campaña] of Object.entries(nuevasCampañas)) {
        this.campañasActuales.push(campaña);
      }
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Generar QR's</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioQrs()">
        <div class="card-body">
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="folioInicial"
                >Folio inicial</label
              >
              <input
                class="form-control"
                id="folioInicial"
                type="number"
                aria-describedby="folioInicial"
                placeholder="Introduce el folio inicial"
                v-model.trim="folioInicial"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="folioFInal"
                >Folio final</label
              >
              <input
                class="form-control"
                id="folioFInal"
                type="number"
                aria-describedby="folioFInal"
                placeholder="Introduce el folio final"
                v-model.trim="folioFinal"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="montoActivacion"
                >Monto activación</label
              >
              <input
                class="form-control"
                id="montoActivacion"
                type="number"
                aria-describedby="montoActivacion"
                placeholder="Introduce el monto de activación"
                v-model.trim="montoActivacion"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="campaña">Campaña</label>
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model.trim="campaña"
              >
                <option v-for="campaña in campañasActuales">
                  {{ campaña.descripcionActivacion }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <label class="col-form-label pt-0" for="fechaNacimiento"
                >Vigencia</label
              >
              <datepicker
                class="datepicker-here digits"
                range
                id="fechaPedidos"
                :enable-time-picker="false"
                type="date"
                locale="es"
                format="dd/MM/yyyy"
                select-text="Confirmar"
                cancel-text="Cancelar"
                v-model="fechasVigencia"
              />
            </div>
            <div class="col-lg-6 col-12 mb-3">
              <label class="col-form-label pt-0" for="fechaNacimiento"
                >Dimensiones del QR en pixeles</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model.trim="dimensionesQr"
              >
                <option>80x80</option>
                <option>128x128</option>
                <option>256x256</option>
                <option>512x512</option>
              </select>
              <p class="m-0">*Sugerido de 128x128</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button class="btn btn-primary w-100" type="submit">
                Generar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- {{ campañasActuales }} -->
  {{ folioInicial }}
  {{ folioFinal }}
  {{ montoActivacion }}
  {{ campaña }}
  {{ dimensionesQr }}
  {{ fechasVigencia }}
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
