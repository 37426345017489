<template>
  <div>
    <div class="logo-wrapper">
      <logoView />
      <div class="back-btn" @click="toggle_sidebar">
        <i data-feather="grid"><vue-feather type="grid"></vue-feather></i>
      </div>
      <div class="toggle-sidebar icon-box-sidebar" @click="toggle_sidebar"> <vue-feather
          class="status_toggle middle sidebar-toggle" type="grid"></vue-feather></div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <div class="icon-box-sidebar"><i data-feather="grid"> <vue-feather type="grid"></vue-feather></i>
        </div>
      </router-link>
    </div>
    <div class="logo-wrapper">
      <div class="profile-title">
        <div class="container">
          <div class="d-lg-flex d-block align-items-center flex-column"><img class="img-70 rounded-circle" alt=""
              :src="urlLogoUsuario">
            <div class="flex-grow-1">
              <h3 class="mb-1 f-20 txt-primary">{{ getUsuarioActual?.nombre }}</h3>
              <p class="f-12 mb-0 txt-secondary">{{ getUsuarioActual?.correo }}</p>
              <p class="f-15 mb-0 txt-light">Division {{ getUsuarioActual?.division }}</p>
              <p class="f-15 mb-0 txt-light">{{ rolCapitalizado }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <navView />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import logoView from "./logoView.vue"
import navView from "./navView.vue"
export default {
  components: {
    logoView,
    navView
  },
  data() {
    return {
      urlLogoUsuario: ""
    }
  },
  created(){
    this.obtenerUrlLogoUsuario();
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
    ...mapGetters("autenticacion", ["getUsuarioActual"]),
    rolCapitalizado(){
      const rolActual = this.getUsuarioActual?.rol;

      return rolActual ? rolActual.charAt(0).toUpperCase() + rolActual.slice(1) : "";
    }
  },
  watch:{
    getUsuarioActual(usuarioActualizado){
      this.obtenerUrlLogoUsuario();
    }
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch('menu/opensidebar');
      this.$store.state.menu.activeoverlay = false;
    },
    obtenerUrlLogoUsuario() {
      if(this.getUsuarioActual){
        if (this.getUsuarioActual?.foto) {
          this.urlLogoUsuario = this.getUsuarioActual?.foto;
        }
        else if (this.getUsuarioActual?.nombre) {
          this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${this.getUsuarioActual?.nombre}&background=random`
        } else {
          this.urlLogoUsuario = `https://ui-avatars.com/api/?name=${this.getUsuarioActual?.correo}&background=random`
        }
      }
    }
  },
  
}
</script>