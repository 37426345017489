export const arraySucursales = {
  sucursalesSk: [
    { nombre: "SK LAS MARINAS", division: 7104 },
    { nombre: "SK LERMA", division: 7105 },
    { nombre: "SK TOLLOCAN", division: 7120 },
    { nombre: "SK PINO SUAREZ", division: 7121 },
    { nombre: "SK TENANCINGO", division: 7122 },
    { nombre: "SK SAN BUENA VENTURA", division: 7125 },
    { nombre: "SK CENTRO", division: 7126 },
    { nombre: "SK LA ASUNCION", division: 7127 },
    { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
    { nombre: "SK ATLACOMULCO", division: 7129 },
    { nombre: "SK ZITACUARO", division: 7130 },
    { nombre: "SK IXTLAHUACA", division: 7150 },
  ],
  sucursalesSkMicro: [
    { division: 7305, nombre: "SKM ZITACUARO" },
    { division: 7306, nombre: "SKM VALLE DE BRAVO AV TOLUCA" },
    { division: 7308, nombre: "SKM SAN PEDRO" },
    { division: 7309, nombre: "SKM SAN MATEO" },
    { division: 7310, nombre: "SKM LAS PARTIDAS" },
    { division: 7319, nombre: "SKM LEANDRO VALLE" },
    { division: 7323, nombre: "SKM FIDEL VELAZQUEZ" },
    { division: 7324, nombre: "SKM VALLE DE BRAVO" },
    { division: 7331, nombre: "SKM XONACATLAN" },
    { division: 7332, nombre: "SKM ALAMEDA" },
    { division: 7333, nombre: "SKM GALERIAS" },
    { division: 7334, nombre: "SKM METEPEC" },
    { division: 7335, nombre: "SKM LERDO" },
    { division: 7337, nombre: "SKM XINANTECATL" },
    { division: 7338, nombre: "SKM SEMINARIO" },
    { division: 7339, nombre: "SKM ATLACOMULCO" },
    { division: 7344, nombre: "SKM SANTIAGO MILTEPEC" },
    { division: 7346, nombre: "SKM TENANGO" },
    { division: 7347, nombre: "SKM IXTLAHUACA" },
  ],
  sucursalesSurtitienda: [
    { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
    { division: 7512, nombre: "ST TENANCINGO" },
    { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
    { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
    { division: 7515, nombre: "ST IXTLAHUACA" },
    { division: 7517, nombre: "ST ZITACUARO" },
  ],
  sucursalesAcropolis: [
    { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
    { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
  ],
};
