<script>
import router from "./router";
export default {
  created() {
    router.beforeResolve(async (to, from, next) => {
      // Antes de abandonar la ruta, verifica si el SweetAlert está abierto y ciérralo.

      this.$swal.close();

      next();
    });
  },
};
</script>
<template>
  <nav></nav>
  <router-view />
</template>

<style lang="scss"></style>
