<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      contraseñaOculta: true,
      botonDesactivado: false,
      correo: '',
      contraseña: '',
      mensajeError: ''
    }
  },
  created() {
  },
  methods: {
    ...mapActions("autenticacion", ['iniciarSesion']),
    mostrarOcultarContraseña() {
      console.log("click");
      this.contraseñaOculta = !this.contraseñaOculta;
    },
    async autenticarUsuario() {
      try {
        this.mensajeError = "";
        this.botonDesactivado = true;
        const resAutenticacion = await this.iniciarSesion({ correo: this.correo, contraseña: this.contraseña });
        console.log(resAutenticacion);
        switch (resAutenticacion) {
          case true:
            this.$router.push("/buscar");
            break;
          case "auth/invalid-email":
            this.mensajeError = "Por favor, introduzca un correo válido"
            break;
          case "auth/missing-password":
            this.mensajeError = "Por favor, introduzca una contraseña"
            break;
          case "auth/user-not-found":
            this.mensajeError = "Correo y/o contraseña incorrectos"
            break;
          case "auth/not-found-db":
            this.mensajeError = "Correo y/o contraseña incorrectos"
            break;
          case "auth/wrong-password":
            this.mensajeError = "Correo y/o contraseña incorrectos"
            break;
          default:
            this.mensajeError = "Error inesperado, intentelo más tarde"
            break;
        }

        if (this.mensajeError) {
          this.$swal({
            icon: 'warning',
            type: 'warning',
            title: 'Oops...',
            text: this.mensajeError
          })
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.botonDesactivado = false;
      }
    }

  }
}
</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-7 b-center bg-size"
        :style="{  backgroundImage: 'url(' + require('../assets/images/fondo-SK-login.jpg') + ')' }" style="background-size: contain; background-repeat: round;">
        <img class="bg-img-cover bg-center" style="display: none;" src="../assets/images/fondo-SK-login.jpg" alt="looginpage">
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div><a class="logo"><router-link to="/"> <img class="img-fluid for-light" src="../assets/images/logo-SK.png"
                    style="height: 5rem; margin:0" alt="looginpage"></router-link></a></div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="autenticarUsuario()">
                <h4 class="text-center">Iniciar Sesión</h4>
                <p class="text-center">Introduce tu correo y contraseña para ingresar</p>
                <div class="form-group">
                  <label class="col-form-label">Correo</label>
                  <input class="form-control" type="email" placeholder="Introduce tu correo" v-model.trim="correo">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <div class="form-input position-relative">
                    <input class="form-control" :type="[contraseñaOculta ? 'password' : 'text' ]" name="login[password]" placeholder="Introduce tu contraseña"
                      v-model.trim="contraseña">
                    <div class="show-hide" @click="mostrarOcultarContraseña()"><span :class="[contraseñaOculta ? 'show' : '' ]"></span></div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="text-end">
                    <a>
                      <router-link to="/recuperar-contrasena">
                        ¿Olvidaste tu contraseña?
                      </router-link>
                    </a>
                  </div>

                  <div class="text-end mt-3">
                    <button class="btn btn-primary btn-block w-100" type="submit">Acceder</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="main-wrapper">
        <div class="preloader" style="display: none;">
            <div class="lds-ripple">
                <div class="lds-pos"></div>
                <div class="lds-pos"></div>
            </div>
        </div>
        <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(images/big/auth-bg.jpg) no-repeat center center;">
            <div class="auth-box">
                <div id="loginform">
                    <div class="logo mb-3">
                        <h5 class="font-medium m-b-20">Iniciar Sesión</h5>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal m-t-20" id="loginform" @submit.prevent="autenticarUsuario()">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-lg" placeholder="Correo" aria-label="Usuario" aria-describedby="Correo" v-model.trim="correo">
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-lg" placeholder="Contraseña" aria-label="Contraseña" aria-describedby="Contraseña" v-model.trim="contraseña">
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <div class="custom-control">
                                            <a href="javascript:void(0)" id="to-recover" class="text-dark float-right"><i class="fa fa-lock m-r-5"></i> Olvidaste tu contraseña?</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group text-center">
                                    <div class="col-xs-12 p-b-20">
                                        <button :disabled="botonDesactivado" class="btn btn-block btn-lg btn-info" type="submit">Ingresar</button>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>
<style>
.show-hide span.show::before{
  content: "Mostrar" !important;
}

.show-hide span::before{
  content: "Ocultar" !important ;
}
</style>