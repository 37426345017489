<script>
export default {
    props:["infoCliente"],
    data(){
        return{
            nombre:"",
            clienteId:"",
            telefono:"",
            direccion:"",
            codigoSk:"",
            comentarios:"",
            fotografia:"",
        }
    },
    mounted(){
        this.cargarInfoCliente();
    },
    methods:{
        cargarInfoCliente(){
            this.nombre = this.infoCliente.nombre;
            this.clienteId = this.infoCliente.clienteId;
            this.telefono = this.infoCliente.telefono;
            this.direccion = this.infoCliente.direccion;
            this.codigoSk = this.infoCliente.codigoSk;
            this.comentarios = this.infoCliente.comentarios;
            this.fotografia = this.infoCliente.fotografia;
        }
    }
}
</script>
<template>
    <div class="card">
        <div class="card-header pb-0">
            <h4>Información de cliente</h4>
        </div>
        <form class="form theme-form">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label" for="nombre">Nombre:</label>
                            <input disabled class="form-control" id="nombre" type="text"
                                 v-model="nombre"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label" for="idCliente">Cliente ID:</label>
                            <input disabled class="form-control" id="idCliente" type="text"
                                 v-model="clienteId"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label" for="telefono">Teléfono:</label>
                            <input disabled class="form-control" id="telefono" type="text" v-model="telefono"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label" for="direccion">Dirección:</label>
                            <input disabled class="form-control" id="direccion" type="text" v-model="direccion"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label" for="codigoTarjeta">Codigo Tarjeta Club:</label>
                                    <input disabled class="form-control" id="codigoTarjeta" type="text"
                                         v-model="codigoSk"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="form-label" for="exampleFormControlTextarea4">Comentarios</label>
                                <textarea disabled class="form-control" id="exampleFormControlTextarea4" rows="3" v-model="comentarios"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="form-label" for="exampleFormControlTextarea4">Fotografia:</label>
                        <img :src="fotografia" alt="">
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
