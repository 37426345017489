<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      folio: "",
      templateID: "",
      email: "",
      name: "",
      senderName: "",
      enviandoCorreo: false
    };
  },
  async created() {
    this.cargarFolio();
    
  },
  methods: {
    ...mapActions("validacion", ["enviarFolioPreregistro"]),
    irPreregistro() {
      this.$router.replace("/cliente/preregistro");
    },
    cargarFolio() {
      var datosFolio = localStorage.getItem("folio");
      console.log(datosFolio);
      if (datosFolio) {
        datosFolio = JSON.parse(datosFolio);
        this.folio = datosFolio.folio;

      } else {
        this.$router.replace("/cliente/preregistro");
      }

      var datosEnvioFolio = localStorage.getItem("datosUsuario");
      if (datosEnvioFolio) {
        datosEnvioFolio = JSON.parse(datosEnvioFolio);
        this.templateID = datosEnvioFolio.templateFolio;
        this.email = datosEnvioFolio.correo;
        this.name = datosEnvioFolio.nombre;
        this.senderName = datosEnvioFolio.nombreSender;
      } else {
        this.$router.replace("/cliente/preregistro");
      }

      this.enviarCodigo();
    },
    async enviarCodigo() {
      try {
        this.enviandoCorreo = true;
        const folioEnviado = await this.enviarFolioPreregistro({
          templateID: this.templateID,
          email: this.email,
          name: this.name,
          folio: this.folio,
          senderName: this.senderName,
        });
        console.log(folioEnviado);
        // const identficador = await this.enviarCodigoCorreo({
        //   correo: this.correoSeleccionado,
        //   nombre: this.nombreSeleccionado,
        //   template: this.templateSeleccionado,
        //   correoSender: this.correoSenderSeleccionado,
        //   nombreSender: this.nombreSenderSeleccionado,
        //   titulo: this.tituloSeleccionado,
        // });
        // this.dbIdentifier = identficador;
        // console.log(this.dbIdentifier);
        if (folioEnviado) {
          toast.success(" ¡Folio de preregistro enviado! ", {
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.enviandoCorreo = false;
      }
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Folio tarjetahabiente</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-5 py-2" style="max-width: 600px">
      <div class="card-header pb-0">
        <h2>Folio generado:</h2>
      </div>
      <div class="card-body" style="height: 25rem">
        <div class="d-flex justify-content-around flex-column" style="height: 100%">
          <h2 class="d-flex justify-content-center">{{ folio }}</h2>

          <h5 class="text-muted">
            Presente este folio en la sucursal seleccionada para recibir su
            tarjeta. En unos momentos le enviaremos un correo con su folio.
          </h5>

          <button v-if="!enviandoCorreo" class="btn btn-primary" @click="irPreregistro()">
            <span>Continuar</span>
          </button>

          <div v-else class="mb-2">
            <div class="d-flex justify-content-center mb-3">
              <h6>Enviando folio...</h6>
            </div>
            <div class="loader-box" style="height: 1rem">
              <div class="loader-7"></div>
            </div>
          </div>
          <div>
            <span class="mt-0" for="nota">Si no ha llegado tu folio dale click al
              siguiente enlace:<a class="d-inline" href="#" @click="enviarCodigo()">
                Reenviar folio
              </a></span>
          </div>

          <!-- <div>
            <span class="mt-0" for="nota"
              >Si no ha llegado el código de verificación dale click al
              siguiente enlace:<a
                class="d-inline"
                href="#"
                @click="enviarCodigo()"
              >
                Reenviar código de verificación
              </a></span
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
