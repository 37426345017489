<script>
    import FormularioAltaUsuarios from '../../components/formularioAltaUsuarios.vue';
export default {
    components:{
        FormularioAltaUsuarios
    }
}
</script>
<template>
    <FormularioAltaUsuarios />
</template>
