<script>
export default {
  props: ["cantidadPedidos", "colorEstatus", "estatus", "total"],
  data() {
    return {};
  },
  computed:{
    totalRedondeado(){
      return Math.round(this.total * 100)/100;
    }
  }
};
</script>
<template>
  <div class="card profit-card">
    <div class="card-header pb-0">
      
        <div class="card mt-0 my-4 mx-">
          <div
            class="card-body rounded py-2 d-flex justify-content-center"
            :style="{ 'background-color': colorEstatus }"
          >
            <span style="color: white"> {{ estatus }} </span>
          </div>
        </div>
        <h4 class="mb-3">{{ cantidadPedidos }} pedidos</h4>
        <h6>Total: {{ total ? totalRedondeado : 0 }}</h6>
        <h6 class="mb-4">MXN</h6>
      </div>
    
  </div>
</template>
