<script>
/* eslint-disable */
import imageMixin from "@/mixins/imageMixin";
import { mapState } from "vuex";
export default {
  mixins: [imageMixin],
  props: ["headers", "data", "campos", "listaEstatus", "title", "total", "titleAmount"],
  data() {
    return {
      sizingTablesXl: [
        {
          id: 1,
          Name: "Mark Jecno",
          Date: "22/08/2023",
          Status: "On leave",
          statusClass: "font-danger",
          hours: "0",
          performance: "29/30",
        },
        {
          id: 2,
          Name: "Elana Robbert",
          Date: "21/08/2023",
          Status: "Present",
          statusClass: "font-success",
          hours: "10",
          performance: "30/30",
        },
        {
          id: 3,
          Name: "John Deo",
          Date: "18/08/2023",
          Status: "On leave",
          statusClass: "font-danger",
          hours: "8",
          performance: "28/30",
        },
      ],
    };
  },
  computed:{
    dataLength(){
      return this.data.length;
    }
  }
};
</script>
<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0" v-if="title">
        <h4>{{ title }}</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive theme-scrollbar">
          <table class="table table-md">
            <thead>
              <tr>
                <th v-for="header in headers">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item">
                <td v-for="campo in campos">
                  <div v-if="campo.tipo === 'imagen'">
                    <img :src="item[campo.clave]" style="height: 4rem" />
                  </div>
                  <div v-if="campo.tipo === 'boton'">
                    <button
                      v-if="campo.funcion === 'remover'"
                      class="btn btn-danger btn-xs me-2"
                      type="button"
                      data-original-title="btn btn-danger btn-xs"
                      title=""
                    >
                      Remover
                    </button>
                    <button
                      v-if="campo.funcion === 'agregar'"
                      class="btn btn-primary btn-xs me-2"
                      type="button"
                      data-original-title="btn btn-danger btn-xs"
                      title=""
                    >
                      Agregar
                    </button>
                    <button
                      v-if="campo.funcion === 'detalle'"
                      class="btn btn-primary btn-xs me-2"
                      style="
                        background-color: #172554 !important;
                        border-color: #172554 !important;
                      "
                      type="button"
                      data-original-title="btn btn-danger btn-xs"
                      title=""
                    >
                      Ver detalle
                    </button>
                  </div>
                  <div
                    v-if="campo.tipo === 'texto'"
                    :class="[item[campo.clave] == '-' ? 'text-center' : '']"
                  >
                    <h6 v-if="campo.bold">{{ item[campo.clave] }}</h6>
                    <span v-else>{{ item[campo.clave] }}</span>
                  </div>
                  <div v-if="campo.tipo === 'estatus'">
                    <div v-for="elementoLista in listaEstatus">
                      <div
                        class="card m-0"
                        v-if="item[campo.clave] == elementoLista.estatus"
                      >
                        <div
                          class="card-body rounded py-2 px-3 text-center"
                          :style="{ 'background-color': elementoLista.color }"
                        >
                          <span style="color: white">
                            {{ item[campo.clave] }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 class="mt-3" v-if="total">Total de {{ dataLength }} {{ titleAmount }}</h5>

      </div>
    </div>
  </div>
</template>
