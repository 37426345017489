<template>
  <div class="account-user"><vue-feather type="user"></vue-feather></div>
  <ul class="profile-dropdown onhover-show-div" style="width: 25rem;">
    <li>
      <h6>
        {{ getUsuarioActual?.nombre }}
      </h6>
      <span>
        {{ getUsuarioActual?.correo }}
      </span>
    </li>
    <li> 
      <a @click="logout" class="d-block" >
        <vue-feather type="log-in"></vue-feather><span>Cerrar Sesión</span>
      </a>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {

  },
  computed: {
    ...mapGetters("autenticacion", ["getUsuarioActual"])
  },
  methods: {
    ...mapActions("autenticacion", ['cerrarSesion']),
    async logout() {
      localStorage.clear();
      // localStorage.removeItem("empleado");
      await this.cerrarSesion();
      this.$router.replace('/login');
    }
  },
}
</script>