<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  props: ["urlSiguiente"],
  data() {
    return {
      validando: false,
      correoSeleccionado: "",
      nombreSeleccionado: "",
      codigoVerificacion: "",
      dbIdentifier: "",
      templateSeleccionado: "",
      correoSenderSeleccionado: "",
      nombreSenderSeleccionado: "",
      tituloSeleccionado: "",
      noCorreo: false,
      altaTarjeta: false,
      preregistro: false,
      activacion: false,
      tarjeta:0
    };
  },
  mounted() {
    this.cargarCorreo();
  },
  methods: {
    ...mapActions("validacion", ["enviarCodigoCorreo", "validarCodigoCorreo"]),
    cargarCorreo() {
      console.log("cARGANDO CORREO");
      let datosUsuario = localStorage.getItem("datosUsuario");
      if (datosUsuario) {
        datosUsuario = JSON.parse(datosUsuario);

        console.log(datosUsuario);
        this.correoSeleccionado = datosUsuario.correo;
        this.nombreSeleccionado =
          datosUsuario.nombre +
          " " +
          datosUsuario.apellidoPaterno +
          " " +
          datosUsuario.apellidoMaterno;
        this.templateSeleccionado = datosUsuario.template;
        this.correoSenderSeleccionado = datosUsuario.correoSender;
        this.nombreSenderSeleccionado = datosUsuario.nombreSender;
        this.tituloSeleccionado = datosUsuario.titulo;
        this.noCorreo = datosUsuario.noCorreo;
        this.altaTarjeta = datosUsuario?.altaTarjeta;
        this.preregistro = datosUsuario?.preregistro;
        this.activacion = datosUsuario?.activacion;
        this.tarjeta = datosUsuario?.tarjeta;

        this.revisarVerificacionCorreo();
      } else {
        this.$router.replace("/verificar");
      }
    },

    async enviarCodigo() {
      try {
        const identficador = await this.enviarCodigoCorreo({
          correo: this.correoSeleccionado,
          nombre: this.nombreSeleccionado,
          template: this.templateSeleccionado,
          correoSender: this.correoSenderSeleccionado,
          nombreSender: this.nombreSenderSeleccionado,
          titulo: this.tituloSeleccionado,
        });
        this.dbIdentifier = identficador;
        console.log(this.dbIdentifier);
        if (identficador) {
          toast.success(" ¡Mensaje de confirmación enviado! ", {
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },

    validarCodigoConfirmacion() {
      this.validando = true;
      const errores = [];

      if (this.codigoVerificacion == "") {
        errores.push({ msg: "Introduzca un código de confirmación" });
      }

      if (
        isNaN(this.codigoVerificacion) ||
        this.codigoVerificacion.length != 6
      ) {
        errores.push({ msg: "Código inválido" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.validando = false;
      } else {
        this.verificarCodigo();
      }
    },

    async verificarCodigo() {
      try {
        const resValidacion = await this.validarCodigoCorreo({
          dbIdentificador: this.dbIdentifier,
          correo: this.correoSeleccionado,
          codigo: this.codigoVerificacion,
        });
        console.log(resValidacion);
        if (resValidacion) {
          this.$swal({
            icon: "success",
            type: "success",
            title: "Correo validado correctamente",
            allowOutsideClick: false,
            confirmButtonText: "Continuar",
          }).then((result) => {
            console.log(result);
            if (result.value) {
              this.$router.push(this.urlSiguiente);
            }
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.validando = false;
      }
    },
    mostrarAlertaConfirmarCorreo() {
      this.$swal({
        title: "¿Desea continuar con la verificación?",
        text: `El correo proporcionado es: ${this.correoSeleccionado}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Regresar",
        showDenyButton: true,
        denyButtonColor: "#d33",
        confirmButtonText: "Si, continuar!",
        denyButtonText: "Cambiar correo",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarCodigo();
        } else if (result.isDenied) {
          this.mostrarAlertaNuevoCorreo();
        } else {
          if (this.altaTarjeta) {
            this.$router.replace("/alta-tarjeta");
          }
          else if (this.preregistro) {
            this.$router.replace("/cliente/preregistro");
          }else if (this.activacion) {
            this.$router.replace(`/cliente/activacion-tarjeta/${this.tarjeta}`);
          }else {
            this.$router.replace("/verificar");
          }
        }
      });
    },
    mostrarAlertaNuevoCorreo() {
      this.$swal({
        text: "Escriba su correo:",
        input: "text",
        showDenyButton: true,
        denyButtonColor: "#d33",
        confirmButtonColor: "var(--theme-deafult)",
        allowOutsideClick: false,
        denyButtonText: "Cancelar",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          this.validarNuevoCorreo(result.value);
        } else {
          this.mostrarAlertaConfirmarCorreo();
        }
      });
    },
    validarNuevoCorreo(correoValidar) {
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const errores = [];

      if (correoValidar == "") {
        errores.push({ msg: "Debes proporcionar un correo" });
      }

      if (!patronCorreo.test(correoValidar)) {
        errores.push({ msg: "Correo inválido" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "error",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        }).then((result) => {
          this.mostrarAlertaNuevoCorreo();
        });
      } else {
        this.correoSeleccionado = correoValidar;
        let objetoDatosUsuario = JSON.parse(
          localStorage.getItem("datosUsuario")
        );
        console.log(objetoDatosUsuario);
        objetoDatosUsuario.correo = this.correoSeleccionado;
        localStorage.setItem(
          "datosUsuario",
          JSON.stringify(objetoDatosUsuario)
        );
        this.mostrarAlertaConfirmarCorreo();
      }
    },
    revisarVerificacionCorreo() {
      console.log("Hola");
      if (this.noCorreo) {
        this.$router.push(this.urlSiguiente);
      } else {
        this.mostrarAlertaConfirmarCorreo();
      }
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Verificar correo electrónico</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-5 py-2" style="max-width: 600px">
      <div class="card-header pb-0">
        <h2>Confirme su correo electrónico</h2>
      </div>
      <form @submit.prevent="validarCodigoConfirmacion()">
        <div class="card-body" style="height: 25rem">
          <div
            class="d-flex justify-content-between flex-column"
            style="height: 100%"
          >
            <h5 class="text-muted">
              Le hemos enviado un código de confirmación al correo:
              <span class="f-w-700 txt-dark">{{ correoSeleccionado }}</span
              >. Por razones de seguridad, el código caducará dentro de 15
              minutos.
            </h5>

            <input
              class="form-control digits"
              type="text"
              placeholder="Código de verificación"
              v-model.trim="codigoVerificacion"
            />

            <button v-if="!validando" class="btn btn-primary" type="submit">
              <span>Verificar correo electrónico</span>
            </button>

            <div v-else class="mb-2">
              <div class="d-flex justify-content-center mb-3">
                <h6>Verificando...</h6>
              </div>
              <div class="loader-box" style="height: 1rem">
                <div class="loader-7"></div>
              </div>
            </div>
            <div>
              <span class="mt-0" for="nota"
                >Si no ha llegado el código de verificación dale click al
                siguiente enlace:<a
                  class="d-inline"
                  href="#"
                  @click="enviarCodigo()"
                >
                  Reenviar código de verificación
                </a></span
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
