<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

import TablaLista from "../../components/tablaLista.vue";
import { arraySucursales } from "@/constants/sucursales";
export default {
  data() {
    return {
      fechaFiltradoPedidos: [],
      division: "",
      sucursal: "",
      listaSucursalesSk: [
        ...arraySucursales.sucursalesSk,
        ...arraySucursales.sucursalesSkMicro,
      ],
      listaSucursalesSurtitienda: [...arraySucursales.sucursalesSurtitienda],
      listaSucursalesAcropolis: [...arraySucursales.sucursalesAcropolis],
      sucursalesFiltradas: [],
      empleado: "",
      promotor: "",
      busqueda: "fecha",
      tarjeta: "",
      headersTabla: [
        "Tarjeta",
        "Id Cliente",
        "Nombre",
        "Apellido paterno",
        "Apellido materno",
        "Email",
        "Teléfono",
        "Fecha de nacimiento",
        "Código postal",
        "Sexo",
        "Recomendo",
        "Sucursal",
        "Dio alta",
        "Fecha alta",
        "Fecha verificación",
        "Pre registro",
        "Promotor",
        "Verificó email",
        "Acciones",
      ],
      campos: [
        { tipo: "texto", clave: "tarjeta" },
        { tipo: "texto", clave: "id_cli" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "apellidoM" },
        { tipo: "texto", clave: "apellidoP" },
        { tipo: "texto", clave: "email" },
        { tipo: "texto", clave: "telefono" },
        { tipo: "texto", clave: "fechanacim" },
        { tipo: "texto", clave: "cp" },
        { tipo: "texto", clave: "sexo" },
        { tipo: "texto", clave: "recomendo" },
        { tipo: "texto", clave: "division" },
        { tipo: "texto", clave: "dioalta" },
        { tipo: "texto", clave: "fechaaltat" },
        { tipo: "texto", clave: "fechaverif" },
        { tipo: "texto", clave: "preregistro" },
        { tipo: "texto", clave: "promotor" },
        { tipo: "texto", clave: "verificoEmail" },
        { tipo: "boton", funcion: "editarTarjeta" },
      ],
      tarjetasFechas: {},
      tarjetasId: {},
      tarjetasConsultados: [],
      tarjetasFiltradas: [],
    };
  },
  components: {
    TablaLista,
  },
  created() {
    this.cargarFechasIniciales();
  },
  computed: {
    ...mapState("validacion", ["tarjetasVerificadas", "tarjetasVerificadasId"]),
    ...mapState("autenticacion", ["usuarioActual"]),
    mostrarFiltradoSucursales() {
      return this.division != "";
    },
    mostrarFiltradoEmpleado() {
      return this.division != "";
    },
  },
  methods: {
    ...mapActions("validacion", [
      "consultarTarjetahabientesVerificados",
      "consultarTarjetahabientesId",
    ]),
    filtrarTarjetas() {
      this.tarjetasFiltradas = this.tarjetasConsultados.filter((tarjeta) => {
        console.log(tarjeta);
        if (
          tarjeta?.division.toString() &&
          this.division
            .toString()
            .split("|")
            .some((subcadena) =>
              tarjeta.division.toString().includes(subcadena)
            ) &&
          tarjeta?.division.toString().includes(this.sucursal.toString()) &&
          tarjeta?.preregistro
            .toString()
            .toUpperCase()
            .includes(this.empleado.toUpperCase()) &&
          tarjeta?.promotor
            .toString()
            .toUpperCase()
            .includes(this.promotor.toUpperCase())
        ) {
          return tarjeta;
        }

        if (this.division === "71|73") {
          this.sucursalesFiltradas = this.listaSucursalesSk;
          if (
            tarjeta?.division.toString() &&
            this.division
              .toString()
              .split("|")
              .some((subcadena) =>
                tarjeta.division.toString().includes(subcadena)
              ) &&
            tarjeta?.division.toString().includes(this.sucursal.toString()) &&
            tarjeta?.preregistro
              .toString()
              .toUpperCase()
              .includes(this.empleado.toUpperCase()) &&
            tarjeta?.promotor
              .toString()
              .toUpperCase()
              .includes(this.promotor.toUpperCase())
          ) {
            return tarjeta;
          }
        } else if (this.division === "75") {
          this.sucursalesFiltradas = this.listaSucursalesSurtitienda;
          if (
            tarjeta?.division.toString() &&
            this.division
              .toString()
              .split("|")
              .some((subcadena) =>
                tarjeta.division.toString().includes(subcadena)
              ) &&
            tarjeta?.division.toString().includes(this.sucursal.toString()) &&
            tarjeta?.preregistro
              .toString()
              .toUpperCase()
              .includes(this.empleado.toUpperCase()) &&
            tarjeta?.promotor
              .toString()
              .toUpperCase()
              .includes(this.promotor.toUpperCase())
          ) {
            return tarjeta;
          }
        } else if (this.division === "77") {
          this.sucursalesFiltradas = this.listaSucursalesAcropolis;
          if (
            tarjeta?.division.toString() &&
            this.division
              .toString()
              .split("|")
              .some((subcadena) =>
                tarjeta.division.toString().includes(subcadena)
              ) &&
            tarjeta?.division.toString().includes(this.sucursal.toString()) &&
            tarjeta?.preregistro
              .toString()
              .toUpperCase()
              .includes(this.empleado.toUpperCase()) &&
            tarjeta?.promotor
              .toString()
              .toUpperCase()
              .includes(this.promotor.toUpperCase())
          ) {
            return tarjeta;
          }
        } else {
          this.sucursalesFiltradas = [];
        }
      });

      this.tarjetasFiltradas = this.tarjetasFiltradas.sort((a, b) => {
        if (a.fechaverif < b.fechaverif) {
          return 1;
        } else if (a.fechaverif > b.fechaverif) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    // ...mapActions("comercio", ["consultarPedidos"]),
    cargarFechasIniciales() {
      const fechaActual = moment();
      const fechaActualSinFecha = fechaActual.format("YYYY-MM-DD");
      const fechaActualformato = moment(fechaActualSinFecha);
      const fechaActualTimestamp = fechaActualformato.format("x");
      const fecha7diasAnteriores =
        parseInt(fechaActualTimestamp) - 6 * 86400000;
      const fechaActualDia =
        parseInt(fechaActualTimestamp) + 23 * 3600000 + 59 * 60000;
      const fechaInicio = moment(fecha7diasAnteriores).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const fechaFin = moment(fechaActualDia).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      this.fechaFiltradoPedidos = [fechaInicio, fechaFin];
      console.log(this.fechaFiltradoPedidos);
      // this.consultarPedidos(this.fechaFiltradoPedidos);
    },
    // async verDetallePedido(elemento) {
    //   let pedidoSeleccionado = this.pedidos[elemento.id];
    //   pedidoSeleccionado['id'] = elemento.id;
    //   localStorage.setItem('detallePedido',JSON.stringify(pedidoSeleccionado));
    //   this.$router.push(`/detalle-pedido/${elemento.id}`);
    //   // this.$swal({
    //   //   title: "¡Producto agregado con éxito!",
    //   //   text: `${elemento?.nombre}`,
    //   //   icon: "success",
    //   //   confirmButtonText: "Continuar",
    //   //   confirmButtonColor: "#10B981"
    //   // })
    // },

    descargarExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Datos_Maestros");
      worksheet.addRow(this.headersTabla.filter((header) => header != "Acciones"));
      this.tarjetasFiltradas.forEach((tarjeta) => {
        
        const row = [
          tarjeta["tarjeta"] ? tarjeta["tarjeta"] : "",
          tarjeta["id_cli"] ? tarjeta["id_cli"] : "" ,
          tarjeta["nombre"] ? tarjeta["nombre"] : "" ,
          tarjeta["apellidoP"] ? tarjeta["apellidoP"] : "" ,
          tarjeta["apellidoM"] ? tarjeta["apellidoM"] : "" ,
          tarjeta["email"] ? tarjeta["email"] : "" ,
          tarjeta["telefono"] ? tarjeta["telefono"] : "" ,
          tarjeta["fechanacim"] ? tarjeta["fechanacim"] : "" ,
          tarjeta["cp"] ? tarjeta["cp"] : "" ,
          tarjeta["sexo"] ? tarjeta["sexo"] : "" ,
          tarjeta["recomendo"] == "-" ? "NULL" : tarjeta["recomendo"] ? tarjeta["recomendo"] : "NULL",
          tarjeta["division"] ? tarjeta["division"] : "0" ,
          tarjeta["dioalta"] ? tarjeta["dioalta"] : "" ,
          tarjeta["fechaaltat"] ? tarjeta["fechaaltat"] : "" ,
          tarjeta["fechaverif"] ? tarjeta["fechaverif"] : "" ,
          tarjeta["preregistro"] == "-" ? "NULL" : tarjeta["preregistro"] ? tarjeta["preregistro"] : "NULL",
          tarjeta["promotor"] ? "true" : "false" ,
          tarjeta["verificoEmail"] ? "true" : "false" ,

        ];

        console.log(row);
        
        worksheet.addRow(row);
      });
      
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Historico de verificacion.xlsx");
      });
    },
    procesarTarjetas(tarjetas){
      console.log(tarjetas);
      console.log("nuevosPreregistros");
      this.tarjetasConsultados = [];

      if (tarjetas) {
        console.log("SI HAY");
        for (const [id, tarjeta] of Object.entries(tarjetas)) {
          const objetoPreregistro = {
            tarjeta: tarjeta["tarjeta"],
            id_cli: tarjeta["id_cli"],
            nombre: tarjeta["nombre"],
            apellidoP: tarjeta["apellidoP"],
            apellidoM: tarjeta["apellidoM"],
            email: tarjeta["email"],
            telefono: tarjeta["telefono"],
            fechanacim: tarjeta["fechanacim"],
            cp: tarjeta["cp"],
            sexo: tarjeta["sexo"],
            recomendo: tarjeta["recomendo"] ? tarjeta["recomendo"] : "-",
            division: tarjeta["division"],
            dioalta: tarjeta["dioalta"],
            fechaaltat: tarjeta["fechaaltat"] ? tarjeta["fechaaltat"] : tarjeta["fechaalta"],
            fechaverif: tarjeta["fechaverif"] ? tarjeta["fechaverif"] : tarjeta["fechaverfi"],
            preregistro: tarjeta["preregistro"] ? tarjeta["preregistro"] : "-",
            promotor: tarjeta["promotor"],
            verificoEmail: tarjeta["verificoEmail"] ? tarjeta["verificoEmail"] : tarjeta["verificoemail"],
          };
          this.tarjetasConsultados.push(objetoPreregistro);
        }
        console.log(this.tarjetasConsultados);
        this.tarjetasFiltradas = this.tarjetasConsultados;
      } else {
        console.log("NO HAY");
        this.tarjetasConsultados = [];
        this.tarjetasFiltradas = this.tarjetasConsultados;
      }
      this.filtrarTarjetas();
    },
    obtenerTarjetasId(){
      try {
        this.consultarTarjetahabientesId(this.tarjeta);
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    }
  },
  watch: {
    busqueda(valorActual){
      console.log(valorActual);
      if(valorActual == "fecha"){
        this.procesarTarjetas(this.tarjetasFechas)  
      }
    },
    tarjetasVerificadas(nuevasTarjetas) {
      this.tarjetasFechas = nuevasTarjetas
      this.procesarTarjetas(nuevasTarjetas)
    },
    tarjetasVerificadasId(nuevasTarjetas) {
      this.tarjetasId = nuevasTarjetas
      const tarjetasProcesar = {
        ...nuevasTarjetas
      }
      this.procesarTarjetas(tarjetasProcesar)
    },
    fechaFiltradoPedidos(fechas) {
      this.consultarTarjetahabientesVerificados(fechas);
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Histórico" />

  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <!-- h -->
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Busqueda por:</label>
            <select
              class="form-select"
              id="busqueda"
              aria-describedby="busqueda"
              v-model="busqueda"
            >
              <option value="fecha">Fecha</option>
              <option value="tarjeta">Tarjeta</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="busqueda == 'tarjeta'">
              <label>Filtrado por tarjeta:</label>
              <input
                class="form-control"
                id="tarjeta"
                type="text"
                aria-describedby="tarjeta"
                placeholder="Tarjeta"
                v-model.trim="tarjeta"
              />
            </div>
            <div v-else>
              <label>Filtrado por fecha:</label>
              <datepicker
                class="datepicker-here digits"
                range
                id="fechaPedidos"
                :enable-time-picker="false"
                type="date"
                locale="es"
                format="dd/MM/yyyy"
                select-text="Confirmar"
                cancel-text="Cancelar"
                v-model="fechaFiltradoPedidos"
              />
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3 d-flex justify-content-center align-items-end">
            <button v-if="busqueda == 'tarjeta'" class="btn btn-primary" style="height: 3rem;" @click="obtenerTarjetasId()">Buscar</button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por Formato:</label>
            <select
              class="form-select"
              id="division"
              aria-describedby="division"
              v-model.trim="division"
              @change="filtrarTarjetas()"
            >
              <option value="">General</option>
              <option value="71|73">Super Kompras</option>
              <option value="75">Surtitienda</option>
              <option value="77">Acropolis</option>
            </select>
          </div>
          <div class="col-lg-4 col-0">
            <label>Promotoria:</label>
            <select
              class="form-select"
              id="promotor"
              aria-describedby="promotor"
              v-model.trim="promotor"
              @change="filtrarTarjetas()"
            >
              <option value="">Ambos</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3 d-flex justify-content-center align-items-end">
            <button
              type="button"
              class="btn btn-outline-success"
              style="height: 3rem;"
              @click="descargarExcel"
            >
              Descargar Excel
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="mostrarFiltradoSucursales">
              <label>Filtrado por Sucursal:</label>
              <select
                class="form-select"
                id="sucursal"
                aria-describedby="sucursal"
                v-model.trim="sucursal"
                @change="filtrarTarjetas()"
              >
                <option value="">Todos</option>
                <option
                  v-for="sucursal in sucursalesFiltradas"
                  :key="sucursal.division"
                  :value="sucursal.division"
                >
                  {{ sucursal.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="mostrarFiltradoEmpleado">
              <label>Filtrado por empleado:</label>
              <input
                class="form-control"
                id="empleado"
                type="text"
                aria-describedby="empleado"
                placeholder="Empleado"
                v-model.trim="empleado"
                @input="filtrarTarjetas()"
              />
            </div>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            
          </div>
        </div>
      </div>

      <div class="card-body">
        <TablaLista
          :data="tarjetasFiltradas"
          :headers="headersTabla"
          :search="false"
          :campos="campos"
          :listaEstatus="listaEstatus"
          @entregarTarjeta="entregarTarjeta"
        ></TablaLista>
      </div>
    </div>
  </div>
</template>
