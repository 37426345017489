import { createStore } from "vuex";
import menu from "./modules/menu";
import layout from "./modules/layout";
import autenticacion from "./modules/autenticacion";
import validacion from "./modules/validacion";
import comercio from "./modules/comercio";
import configuracion from "./modules/configuracion";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
  },
  modules: {
    menu,
    layout,
    autenticacion,
    validacion,
    comercio,
    configuracion,
  },
});
